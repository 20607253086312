const isIOS = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
].includes(navigator.platform)
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

var isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

export const getPlatformDependentLink = () => {
    return window.innerWidth < 480 ?
        isIOS ?
            "https://www.apple.com/in/app-store/" :
            isAndroid ?
                "https://play.google.com/store/apps" :
                "https://deall.app/" :
        "https://deall.app/"
}

export  const english_iscore = "https://www.iscore.com.eg/en/about-iscore/about-the-egyptian-credit-bureau-i-score/";
export const arabic_iscore = "https://www.iscore.com.eg/ar/about-i-score/about-the-egyptian-credit-bureau-i-score/";
export const rise_wallet = "https://riseco.onelink.me/b7r1/vc2ol3hz";
export const whatsapp_link = "https://wa.me/2001000088792?text=%D8%A7%D9%87%D9%84%D8%A7%D8%8C%20%D8%B9%D9%86%D8%AF%D9%8A%20%D8%A7%D8%B3%D8%AA%D9%81%D8%B3%D8%A7%D8%B1%20%D9%81%D9%8A%20%D8%AA%D8%B7%D8%A8%D9%8A%D9%82%20%D8%B1%D8%A7%D9%8A%D8%B2%D8%8C%20%D9%85%D9%85%D9%83%D9%86%20%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D9%87%D8%9F"