import React, { FC } from 'react'
import applyForRiseMobile from "../../../assets/images/samsung-step-2-mobile.png"
import getPhoneMobile from "../../../assets/images/samsung-step-3-mobile.png"
import easyPaymentMobile from "../../../assets/images/samsung-step-4-mobile.png"
import samsungPhone from "../../../assets/images/samsung-phone.png"
import "./stepsforpurchase.scss"
import { getDeviceType } from '../../../shared/utils/getWindowSize'
import i18next from 'i18next'
import { getCookies } from '../../../shared/utils/cookiesHelper'
import { useTranslation } from 'react-i18next'

interface StepsForPurchaseProps { }

const StepsForPurchase: FC<StepsForPurchaseProps> = (props) => {

    const deviceType = getDeviceType();
    const isTabletOrDesktop = deviceType === 'desktop' || deviceType === 'tablet';
    const isMobile = deviceType === 'mobile'
    const { t } = useTranslation();
    const language = getCookies().i18next || i18next.language;
    const isArabic = language == "ar" && 'arabic';
    const steps = [
        <div className={`container`}>
            <div className={`title`}>
                1. {t('select_your_device')}
                <div className='description'>{t('select_your_device_description')}</div>
            </div>
            <div><img className='step-image' src={samsungPhone} /></div>
        </div>,
        <div className={`container`}>
            <div className={`title`}>
                2. {t('apply_get_approved')}
                <div className='description'>{t('apply_get_approved_description')}</div>
            </div>
            <div><img className='step-image' src={applyForRiseMobile} /></div>
        </div>,
        <div className={`container`}>
            <div className={`title`}>
                3. {t('get_new_phone')}
                <div className='description'>
                    {/* Highlight only words 'Mobillaty', 'City Center Almaza', 'Mall of Arabia' to match design */}
                    {(() => {
                        const text = t('get_new_phone_description');
                        const keywords = ['Mobillaty', 'City Center Almaza', 'Mall of Arabia'];

                        const highlightWords = (text: string): (string | JSX.Element)[] => {
                            return keywords.reduce<(string | JSX.Element)[]>((elements, keyword) => {
                            const keywordIndex = text.indexOf(keyword);
                        
                            if (keywordIndex !== -1) {
                                if (keywordIndex > 0) {
                                elements.push(text.slice(0, keywordIndex));
                                }
                        
                                elements.push(<span className='highlight'>{keyword}</span>);
                        
                                text = text.slice(keywordIndex + keyword.length);
                            }
                        
                            return elements;
                            }, []).concat(text);
                        };
                        

                        return <>{highlightWords(text)}</>;
                    })()}
                </div>


            </div>
            <div><img className='step-image' src={getPhoneMobile} /></div>
        </div>,
        <div className={`container`}>
            <div className={`title`}>
                4. {t('pay_easy_installments')}
                <div className='description'>{t('pay_easy_installments_description')}</div>
            </div>
            <div><img className='step-image' src={easyPaymentMobile} /></div>
        </div>,
    ]

    return (
        <div className={`stepsforpurchase ${isArabic}`}>
            <div className={`steps-header ${isArabic}`}>{t('say_hello')}</div>
            {steps.map(step => step)}
        </div>
    )
}

export default StepsForPurchase