import React, { FC } from 'react'
import "./iscorelandingpage.scss"
import HeaderComponent from '../../shared/components/HeaderComponent'
import redBell from '../../assets/images/bell.webp'
import loanIcon from '../../assets/images/loan.webp'
import clockIcon from '../../assets/images/clock.webp'
import whatsappIcon from '../../assets/images/whatsapp-icon-green.svg'
import phoneIcon from '../../assets/images/phone-icon.svg'
import rightArrowIcon from '../../assets/images/right-arrow.svg'
import leftArrowIcon from '../../assets/images/arrow-left.svg'
import { Divider } from 'antd'
import ButtonComponent from '../../shared/components/ButtonComponent'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { getCookies } from '../../shared/utils/cookiesHelper'
import { arabic_iscore, english_iscore, whatsapp_link, rise_wallet } from '../../shared/utils/getCtaLink'
import { getOS } from '../../shared/utils/getOSDetail'
import { getDeviceType } from '../../shared/utils/getWindowSize'
import { openAppStore, openGooglePlaystore } from '../../shared/utils/openApppStore'

interface IscoreLandingPageProps { }

const IscoreLandingPage: FC<IscoreLandingPageProps> = (props) => {
    const { t } = useTranslation()
    const language = getCookies().i18next || i18next.language;
    const isArabic = language === "ar" && 'arabic';
    const os = getOS();
    const deviceType = getDeviceType();
    const isWindows = os === 'Windows';
    const isMac = os === 'MacOS';
    const isMobile = deviceType === 'mobile'
    
    return (
        <div className={`iscorelandingpage ${isArabic}`}>
            <div className="iscorelandingpage__container">
                <HeaderComponent />
                <div className="iscorelandingpage__content">
                    <div className="iscorelandingpage__alert">
                        <div className="iscorelandingpage__alert-icon">
                            <img src={redBell} alt="Alert" />
                        </div>
                        <div className="iscorelandingpage__alert-content">
                            {!isMobile && <div className='title'>{t('iscore_risk')} {t('iscore_risk_2')}</div>}
                            {isMobile &&  <div className='title'>{t('iscore_risk')}</div>}
                            {isMobile &&  <div className='title'>{t('iscore_risk_2')}</div>}
                            <div className='description'>
                                {t('iscore_impact')} <span className='to-avoid'>{t('iscore_to_avoid')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iscorelandingpage__card">
                <div className="iscorelandingpage__card-content">
                    <div className='header'>{t('iscore_what')}</div>
                    <div className='description'>{t('iscore_who')}</div>
                    <div className='button' onClick={() => window.open(isArabic ? arabic_iscore : english_iscore, '_blank')}>{t('iscore_about')}</div>
                    <Divider />
                    <div className='subheader'>{t('iscore_how_it_affects')}</div>
                    <div className='description-content first'>
                        <div className='icon'>
                            <img className='icon' src={loanIcon} alt="Loan" />
                        </div>
                        <div className='content'>
                            <div className='header'>{t('iscore_harder_loans')}</div>
                            <div className='description'>{t('iscore_late_payments')}</div>
                        </div>
                    </div>
                    <div className='description-content'>
                            <div className='icon'>
                                <img className='icon' src={clockIcon} alt="Loan" />
                            </div>
                            <div className='content'>
                                <div className='header'>{t('iscore_long_term_impact')}</div>
                                <div className='description'>{t('iscore_lender_trust')}</div>
                            </div>
                        </div>
                    <Divider />
                    <div className='header'>{t('iscore_what_need_to_do')}</div>
                    <div className='description'>{t('iscore_pay_overdue')}</div>
                    <Divider />
                    <div className='contact-us-container'>
                        <div className='contact-us-content'>
                            <div className='header'>{t('iscore_chat_with_us')}</div>
                            <a href={whatsapp_link} className='contact-container'>
                                <div className='icon'><img src={whatsappIcon} alt="Whatsapp" /></div>
                                <div className='content'>{t('iscore_whatsapp')}</div>
                                <div className='button'><img src={isArabic ? leftArrowIcon : rightArrowIcon} alt="Right Arrow" /></div>
                            </a>
                            <a href="tel:0233335526" className='contact-container'>
                                <div className='icon'><img src={phoneIcon} alt="Phone" /></div>
                                <div className='content'>02 3333 5526</div>
                                <div className='button'><img src={isArabic ? leftArrowIcon : rightArrowIcon} alt="Right Arrow" /></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='button-container'>
                <ButtonComponent onClick={() => isMobile ? window.open(rise_wallet, '_blank') : isWindows ? openGooglePlaystore() : isMac ? openAppStore() : window.open(rise_wallet, '_blank') }>
                    {t('iscore_pay_now')}
                </ButtonComponent>
            </div>
        </div>
    )
}

export default IscoreLandingPage
