import i18next from 'i18next'
import React, { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import logo from "../../../assets/images/logo-with-hummingbird.svg"
import logoWhite from "../../../assets/images/logo-white.svg"
import { getCookies } from '../../utils/cookiesHelper'
import ChangeLanguageForm from '../ChangeLanguageForm'
import "./headercomponent.scss"

interface HeaderComponentProps { }

const HeaderComponent: FC<HeaderComponentProps> = () => {

    const [language, setLanguage] = useState<string>("ar");
    const [searchParams] = useSearchParams();
    const location = window.location.pathname.split('/')[1]
    const isIscoreLandingPage = location === "what-is-iscore"

    useEffect(() => {
        i18next.changeLanguage("ar")
    }, [])

    useEffect(() => {
        const language = searchParams.get("lang") || getCookies().i18next || i18next.language;
        i18next.changeLanguage(language as string)
        setLanguage(language as string)
      }, [searchParams, i18next.language])

    return (
        <div className={`headercomponent ${language === "ar" ? 'align_right' : ''}`}>
            <div className="logo-container">
                <img src={isIscoreLandingPage ? logoWhite : logo} alt="" className='humming-bird' />
            </div>
            <ChangeLanguageForm color={isIscoreLandingPage ? "white" : "black"} />
        </div>
    )
}

export default HeaderComponent