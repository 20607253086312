export const AppRoutes = {
  ROOT: "/",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  AMBASSADORS: "/ambassadors",
  LEGAL: "/legal",
  SAMSUNG: "/samsung",
  SAMSUNG_FB: "/samsung-fb",
  SAMSUNG_INSTA: "/samsung-insta",
  ISCORE_LANDING_PAGE: "/what-is-iscore"
};
