import { Form, Formik } from 'formik'
import i18next from 'i18next'
import React, { useState } from 'react'
import { getCookies } from '../../utils/cookiesHelper'
import DropdownField from '../DropdownField'
import "./changelanguageform.scss"
import languageIcon from "./../../../assets/images/GlobeSimple.svg"
import languageIconBlue from "./../../../assets/images/GlobeSimpleBlue.png"
import languageIconWhite from "./../../../assets/images/GlobeSimpleWhite.svg"
import { useSearchParams } from 'react-router-dom'

const ChangeLanguageForm = ({changeHandler, color}: {changeHandler?: Function, color: "blue"|"black"|"white"}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const languageInCookie = getCookies().i18next || searchParams.get("lang") || i18next.language;
    const [language, setLanguage] = useState<string>(languageInCookie ?? "ar");
    const handleChange = (_language: string) => {
        changeHandler && changeHandler(_language) 
        setSearchParams(new URLSearchParams({lang: _language}))
        i18next.changeLanguage(_language);
        setLanguage(_language)
    };

    return (
        <div className="change-language-form__wrapper">
            <Formik
                enableReinitialize
                initialValues={{ lang: language }}
                onSubmit={() => { }}
            >
                {({ setFieldValue, values, errors, handleSubmit }) => {
                    return (
                        <Form className={`change-language-form ${language === "ar" && 'arabic'} ${color === "blue" && "blue"}`} >
                            <img className={`language-icon`} src={color === "blue" ? languageIconBlue : color === "white" ? languageIconWhite : languageIcon} alt="" />
                            <DropdownField
                                color={color}
                                label=""
                                options={[
                                    {
                                        value: "en",
                                        label: "EN" ,
                                    },
                                    {
                                        value: "ar",
                                        label: "عربي",
                                    },
                                ]}
                                name="lang"
                                placeholder="Select"
                                onChange={(value) => handleChange(value)}
                                value={languageInCookie||values?.lang}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default ChangeLanguageForm